import {
  VStack,
  HStack,
  Image,
  Center,
  Box,
  Flex,
  Button,
} from "@chakra-ui/react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from "react-admin";

import { ColorInput } from "react-admin-color-input";
import { Discount } from "../../components/atoms/Discount";
import SuperAppMain from "./SuperApp/SuperAppMain";

import { Form, useFormState } from "react-final-form";
import RichTextInput from "ra-input-rich-text";
import AgreementApp from "./AgreementApp";
import { Heading } from "@chakra-ui/layout";

function RenderFieldIfIntegrationIn({ integrations, children }) {
  const { values } = useFormState();
  const { integration_id } = values;

  // if (integrations.indexOf(integration_id) == -1) return <></>;

  return <span style={{ width: "300px" }}>{children}</span>;
}

const SandboxPhone = (props) => {
  const { values } = useFormState();
  const {
    logo,
    menu_color,
    menu_font_color,
    primary_color,
    primary_font_color,
    secondary_color,
    secondary_font_color,
  } = values;

  return (
    <Flex flex="1">
      <VStack
        backgroundImage="url('/phone.png')"
        height="400px"
        backgroundPosition="top-left"
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        width="300px"
        padding="11px 83px 0px 49px"
        position="relative"
        spacing={0}
      >
        <HStack
          padding="20px"
          paddingTop="20px"
          width="100%"
          backgroundColor={menu_color}
          alignItems="center"
          position="relative"
        >
          <FontAwesomeIcon
            style={{ position: "absolute", left: 10 }}
            color={menu_font_color}
            icon={faBars}
          />
          <Center width="100%" left="0px" position="absolute">
            <Image height="30px" src={logo} />
          </Center>
        </HStack>
        <Box
          height="50px"
          width="100%"
          backgroundImage={`linear-gradient(135deg, ${primary_color} 0%, ${secondary_color} 100%)`}
        />
        <Flex flexDirection="column" width="90%" paddingTop="20px">
          <Discount
            title="Teste"
            subTitle="Rua teste 121"
            distance="300m"
            image="https://partiu-rest-s3.s3.amazonaws.com/discount_images/bibi.jpg"
            secondaryColor={secondary_color}
            secondaryFontColor={secondary_font_color}
          />
          <Button
            marginTop="20px"
            backgroundColor={primary_color}
            color={primary_font_color}
            _hover={{
              backgroundColor: secondary_color,
              color: secondary_font_color,
            }}
          >
            Botão Exemplo
          </Button>
        </Flex>
      </VStack>
    </Flex>
  );
};

const CustomizationForm = (props) => (
  <HStack width="100%" justifyContent="flex-start">
    <VStack flex="2" alignItems="flex-start">
      <BooleanInput
        source="in_app_landing"
        label="Esconder banner de instale o app da Partiu!"
        defaultChecked
      />
      <ImageField source="logo" label="Logo Atual" {...props} />
      <ImageInput {...props} source="logo" label="Logo">
        <ImageField {...props} source="logo" title="Logo" />
      </ImageInput>
      <ColorInput {...props} source="menu_color" label="Cor do Menu" />
      <ColorInput
        {...props}
        source="menu_font_color"
        label="Cor da Fonte do Menu"
      />
      <ColorInput {...props} source="primary_color" label="Cor Primária" />
      <ColorInput
        {...props}
        source="primary_font_color"
        label="Cor da Fonte Primária"
      />
      <ColorInput {...props} source="secondary_color" label="Cor Secundária" />
      <ColorInput
        source="secondary_font_color"
        label="Cor da Fonte Secundária"
        {...props}
      />
    </VStack>
    <SandboxPhone />
  </HStack>
);

export const AgreementTabbedForm = (props) => {
  return (
    <TabbedForm {...props}>
      <FormTab label="Dados">
        <TextInput required={true} source="name" label="Nome" />
        <ReferenceInput reference={"agreement"} source={"supervisor"}>
          <AutocompleteInput source={"name"} />
        </ReferenceInput>
        <ReferenceInput reference="plan" isRequired source="plan" label="Plano">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="start_date" label="Data de Início" />
        <NumberInput source="value" defaultValue="0" label="Preço" />
        <TextInput required source="email" label="Email" />
        <TextInput required source="cnpj" label="CNPJ" />
        <TextInput source="site" label="Site da empresa" />
        <TextInput source="url_app_ios" label="URL do APP IOS na loja Apple" />
        <TextInput source="slug_app" label="Slug APP da Loja Google" />
        <BooleanInput defaultValue={true} source="active" label="Ativo" />
        <ReferenceArrayInput label="Níveis" reference="level" source="levels">
          <SelectArrayInput source="name" />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="Módulos">
        <Heading>Módulos de Vinculação</Heading>
        <BooleanInput
          source="promocode_module"
          label="Módulo Vinculação por Promocode"
        />
        <BooleanInput source="cpf_module" label="Módulo Vinculação de CPF" />
        <BooleanInput
          source="physical_card_module"
          label="Módulo Vinculação por Cartão Físico"
        />
        <Heading>Módulos de Vantagens</Heading>
        <BooleanInput source="local_module" label="Módulo Vantagens Locais" />
        <BooleanInput
          source="ecommerce_module"
          label="Módulo Vantagens Ecommerce"
        />
        <BooleanInput source="bookstore_module" label="Módulo Biblioteca" />
        <Heading>Módulos de Funcionalidades</Heading>
        <BooleanInput source="referral_module" label="Módulo Indicações" />
        <BooleanInput source="econometer_module" label="Módulo Econômetro" />
        <BooleanInput source="be_partner_module" label="Módulo Seja Parceiro" />
        <BooleanInput
          source="plan_sale_module"
          label="Módulo Venda de Planos"
        />
        <BooleanInput
          source="digital_magazines_module"
          label="Módulo de revistas digitais"
        />
        <BooleanInput
          source="push_notifications_module"
          label="Módulo Push Notifications"
        />
        <BooleanInput
          source="has_integrated_purchase_on_mobile_store"
          label="Possui integração de pagamento na versao do APP"
        />
        <BooleanInput
          source="allow_default_plan_on_integration"
          label="Permite mapeamento de plano default na integração"
        />
        <TextInput
          source="ura_module_cellphone"
          label="Telefone para URA (deixe em branco para desabilitar)"
        />
        <SelectArrayInput
          label="Tipo de mídias disponíveis"
          source="bookstore_types"
          choices={[
            { id: "book", name: "Livros" },
            { id: "magazine", name: "Revista" },
            { id: "audiobook", name: "Audiobook" },
          ]}
        />
      </FormTab>
      <FormTab label="Modulos Super App">
        <SuperAppMain />
      </FormTab>
      <FormTab label="Permissões e Limites">
        <NumberInput source="user_limit" label="Limite de Vínculos de CPF" />
        <NumberInput
          source="promocode_limit"
          label="Limite de Códigos Promocodes"
        />
      </FormTab>
      <FormTab label="Customização">
        <CustomizationForm />
      </FormTab>
      <FormTab label="Indicação">
        {props.record.referral_image ? (
          <ImageField source="referral_image" label="Imagem Atual" />
        ) : (
          <></>
        )}
        <RichTextInput
          source="referral_offer"
          label="Descrição do benefício para indicação. "
        />
      </FormTab>
      <FormTab label="Filtrar Vantagens">
        <ReferenceArrayInput
          reference="company"
          source="hide_companies"
          label="Esconder Empresas"
        >
          <AutocompleteArrayInput isFullWidth />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          reference="category"
          source="hide_categories"
          label="Esconder Categorias"
        >
          <AutocompleteArrayInput isFullWidth />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="Integração">
        <SelectInput
          choices={[
            { id: "nação", name: "Flamengo" },
            { id: "tim", name: "Tim" },
            { id: "ixc", name: "IXC" },
            { id: "mk", name: "MK" },
            { id: "sgp", name: "SGP" },
            { id: "playhub", name: "Playhub" },
            { id: "dtel", name: "Dtel" },
            { id: 'rbx', name: 'RBX' },
            { id: "easy", name: "Easy" },
            { id: "top", name: "Top" },
            { id: "itnet", name: "ItNet" },
            { id: "az", name: "7az" },
            { id: "youcast!", name: "youcast" },
            { id: "gfsis", name: "GFsis" },
            { id: "neo", name: "NEO" },
            { id: "certirio", name: "Certirio" },
            { id: "isuper", name: "iSuper" },
            { id: "voalle", name: "Voalle" },
            { id: "hubsoft", name: "Hubsoft" },
            { id: "sercomtel", name: "Sercomtel" },
          ]}
          source="integration_id"
          label="Integração"
          allowEmpty={true}
        />
        <TextInput
          source="integration_id"
          label="ID da integração"
          helperText="Exemplos aplicaveis: mk,isuper,certirio,ixc,itnet,voalle,hubsoft,sercomtel,maxx,leste,gfsis,playhub,top,sgp,az"
        />
        <BooleanInput
          source="is_default_agreement"
          label="É Convênio Padrão (Convênio Partiu)"
        />
        <RenderFieldIfIntegrationIn
          integrations={[
            "dtel",
            "easy",
            "gfsis",
            "hubsoft",
            "ixc",
            "mk",
            "sgp",
            "top",
            "sercomtel",
          ]}
        >
          <TextInput
            source="integration_host"
            fullWidth
            label="Host de Integração"
            helperText="No formato https://integrador.com.br"
          />
        </RenderFieldIfIntegrationIn>
        <RenderFieldIfIntegrationIn integrations={["hubsoft"]}>
          <TextInput
            source="integration_client_id"
            fullWidth
            label="Cliente Id de Integração"
            helperText="138"
          />
        </RenderFieldIfIntegrationIn>
        <RenderFieldIfIntegrationIn
          integrations={[
            "hubsoft",
            "dtel",
            "az",
            "easy",
            "ixc",
            "mk",
            "sgp",
            "top",
            "sercomtel",
          ]}
        >
          <TextInput
            source="integration_token"
            fullWidth
            label="Token de Integração"
            helperText="No formato jhziux23a-12312jnash-zkxchzox123-aslkdj123"
          />
        </RenderFieldIfIntegrationIn>
        <RenderFieldIfIntegrationIn integrations={["hubsoft"]}>
          <TextInput
            source="integration_client_id"
            fullWidth
            label="Cliente  ID"
            helperText=""
          />
        </RenderFieldIfIntegrationIn>
        <RenderFieldIfIntegrationIn integrations={["dtel"]}>
          <TextInput
            source="integration_token_2"
            fullWidth
            label="Token de Integração 2"
            helperText="No formato jhziux23a-12312jnash-zkxchzox123-aslkdj123"
          />
        </RenderFieldIfIntegrationIn>
        <RenderFieldIfIntegrationIn
          integrations={[
            "dtel",
            "rbx",
            "gfsis",
            "hubsoft",
            "youcast",
            "top",
            "sgp",
            "sercomtel",
          ]}
        >
          <TextInput
            source="integration_name"
            fullWidth
            label="Nome na Integração"
          />
        </RenderFieldIfIntegrationIn>
        <RenderFieldIfIntegrationIn
          integrations={[
            "dtel",
            "rbx",
            "easy",
            "gfsis",
            "hubsoft",
            "mk",
            "top",
            "sercomtel",
          ]}
        >
          <TextInput
            source="integration_password"
            fullWidth
            label="Senha para Integração"
          />
        </RenderFieldIfIntegrationIn>
        <RenderFieldIfIntegrationIn
          integrations={["playhub", "neo", "sercomtel"]}
        >
          <TextInput
            source="integrated_login"
            fullWidth
            label="Login integrado"
            helperText="Playhub, youcast, neo etc"
          />
        </RenderFieldIfIntegrationIn>
        <RenderFieldIfIntegrationIn integrations={["playhub", "neo"]}>
          <TextInput
            source="idp"
            label="IDP"
            fullWidth
            helperText="ISP, whitelabel para NEO, Playhub etc"
          />
        </RenderFieldIfIntegrationIn>
        <RenderFieldIfIntegrationIn integrations={["hubsoft"]}>
          <BooleanInput
            source="integration_special_case"
            label="É caso especial?"
            fullWidth
            helperText="Ex: Cliente obrigado a ter pacote Partiu na Hubsoft"
          />
        </RenderFieldIfIntegrationIn>
        <BooleanInput
          source="can_use_dataless_integration"
          label="Pode usar integração sem email e CPF"
        />
        <BooleanInput
          source="third_party_login"
          label="Usa login de terceiros"
        />
      </FormTab>
      <FormTab label="Aplicativo">
        <FunctionField
          label="Gerar aplicativo mobile"
          render={(agreement) => (
            <AgreementApp agreement_name={agreement.name} />
          )}
        />
      </FormTab>
      <FormTab label={"Legal"}>
        <RichTextInput source={"legal_text"} label="Termo de Uso" />
        <RichTextInput
          source={"privacy_policy"}
          label="Política de Privacidade"
        />
      </FormTab>
    </TabbedForm>
  );
};
